import React from "react";
import LowerNavbar from "../LowerNavbar/LowerNavbar";
import CatlotusNav from "../Navbar/CatlotusNav";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styles from "./OrderDetail.module.css";
import Footer from "../Footer/Footer";
import { serverInstance } from "../../axiosInstance";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const OrderDetail = (props) => {
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState([]);
  const location = useLocation();
  const params = useParams(); // Use useParams to get id from URL
  const id = location?.state?.id || params.id;

  const dataArray = Object.entries(first);
  const [total, setTotal] = useState(0);
  const [totalP, setTotalP] = useState(0);
  const Comunas = [
    "Cerrillos",
    "Cerro Navia",
    "Colina",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "María Pinto",
    "Ñuñoa",
    "Padre Hurtado",
    "Peñaflor",
    "Peñalolén",
    "Pirque",
    "Providencia",
    "Pudahuel",
    "Puente Alto",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Bernardo",
    "San Joaquín",
    "San Miguel",
    "San Pedro",
    "San Ramón",
    "Santiago",
    "Vitacura",
  ];
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const year = String(date.getUTCFullYear()).slice(-2); // Get the last two digits of the year

    return `${hours}:${minutes} - ${day}:${month}:${year}`;
  }
  useEffect(() => {
    // Calculate total and totalP based on the 'row' array
    if (second) {
      let newTotal = 0;
      let newTotalP = 0;

      second.forEach((item) => {
        newTotal += Number(item?.cantidad);
        newTotalP += Number(item?.cantidad * item?.precio_carta);
      });

      setTotal(newTotal);
      setTotalP(newTotalP);
    }
  }, [second]);
  useEffect(() => {
    One();
  }, [id]);
  const One = () => {
    serverInstance
      .get(`/user/detalleTable/${id}`)
      .then((res) => {
        setFirst(res?.data?.orden[0] || {});
        setSecond(res?.data?.resultado);

        console.log(res, "res?.data?.resultado");
      })
      .catch((err) => {
        console.log("in error", err);
      });
  };

  return (
    <>
      <CatlotusNav />
      <LowerNavbar />
      <div className={styles.detailArea}>
        <div className={styles.detailIner}>
          <div className={styles.detailmost}>
            <div className={styles.uperArea}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow}> Datos</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow}> Información</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Nombre</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.c_nombre}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Apellido</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.apellido}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Rut</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.c_rut}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Correo</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.mail}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Sucursal</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.sucursal}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Region</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.region}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Comuna</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.comuna}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Calle</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.calle}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Numero</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.numero_c}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Departamento</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.departamento}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Block</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>{second[0]?.c_block}</div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Telefono</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.telefono}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Estado</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.estado_c === 0 && "Pendiente"}
                          {second[0]?.estado_c === 1 && "Confirmada"}
                          {second[0]?.estado_c === 2 && "Empacada"}
                          {second[0]?.estado_c === 3 && "Enviada"}
                          {second[0]?.estado_c === 4 && "Rechazada"}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          Numero de seguimiento{" "}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.numero_seguimiento}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Instrucciones</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.c_instrucciones}
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="right">
                        <div className={styles.aboid}>Método entrega </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.aboid}>
                          {second[0]?.retiro === 1
                            ? "Retiro"
                            : Comunas.includes(second[0]?.comuna)
                            ? "Envío particular"
                            : "Chilexpress"}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className="tdd">
                      <TableCell align="right">
                        <div className={styles.topRow1}> Cantidad</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Precio individual</div>
                      </TableCell>
                      {/* <TableCell align="right">
                        <div className={styles.topRow1}> Precio total</div>
                      </TableCell> */}
                      <TableCell align="right">
                        <div className={styles.topRow1}> Carta</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Edición</div>
                      </TableCell>
                      {/* <TableCell align="right">
                        <div className={styles.topRow1}> Numero de Carta</div>
                      </TableCell> */}
                      <TableCell align="right">
                        <div className={styles.topRow1}> Idioma</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Condicion</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Foil/Firmada</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className={styles.topRow1}> Collector Number</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {second?.map((row, index) => (
                      <TableRow
                        key={row?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.cantidad == "1" ? "" : row?.cantidad}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            ${row?.precio_carta?.toLocaleString("es-CL")}
                          </div>
                        </TableCell>
                        {/* <TableCell align="right">
                          <div className={styles.aboid}>
                            ${row?.monto?.toLocaleString("es-CL")}
                          </div>
                        </TableCell> */}
                        <TableCell align="right">
                          <div className={styles.aboid}>{row?.nombre}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.set.toUpperCase()}
                          </div>
                        </TableCell>
                        {/* <TableCell align="right">
                          <div className={styles.aboid}>{row?.Numero}</div>
                        </TableCell> */}
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.idioma.toUpperCase() == "ENG"
                              ? ""
                              : row?.idioma.toUpperCase()}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.estado === 0 && <span></span>}

                            {row?.estado === 1 && <span>SP</span>}

                            {row?.estado === 2 && <span>MP</span>}

                            {row?.estado === 3 && <span>HP</span>}

                            {row?.estado === 4 && <span>DMG</span>}

                            {row?.estado === 5 && <span>ALT</span>}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={styles.aboid}>
                            {row?.signed === 1
                              ? ""
                              : (row?.foil === 1 && "Foil") ||
                                (row?.foil === 2 && "Etched") ||
                                (row?.foil === 3 && "Oversized") ||
                                (row?.foil === 4 && "Oversized Foil") ||
                                (row?.foil === 5 && "Gilded") ||
                                (row?.foil === 6 && "Texturized") ||
                                (row?.foil === 7 && "Surge") ||
                                (row?.foil === 8 && "Galaxy") ||
                                (row?.foil === 9 && "Oil Slick") ||
                                (row?.foil === 10 && "Step-and-Compleat") ||
                                (row?.foil === 11 && "Halo") ||
                                (row?.foil === 12 && "Confetti") ||
                                (row?.foil === 13 && "Double Rainbow")}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <div className={styles.aboid}>
                            {row?.collector_number}
                          </div>
                        </TableCell>{" "}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: "40px" }}>
                <p className={styles.pfont}>Total de cartas: {total}</p>
                <p className={styles.pfont}>
                  SubTotal: ${totalP?.toLocaleString("es-CL")}
                </p>
                <p className={styles.pfont}>
                  Envio: $
                  {first?.retiro == 0
                    ? "400"?.toLocaleString("es-CL")
                    : "0"?.toLocaleString("es-CL")}
                </p>
                <p className={styles.pfont}>
                  Credito: ${first?.credito?.toLocaleString("es-CL")}
                </p>
                <p className={styles.pfont}>
                  Total: $
                  {first?.retiro == 0
                    ? Math.max(
                        totalP + 400 - (first?.credito || 0),
                        0
                      ).toLocaleString("es-CL")
                    : Math.max(
                        totalP - (first?.credito || 0),
                        0
                      ).toLocaleString("es-CL")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetail;
